import $ from "jquery";
import {
  isCompExists,
  logFactory,
  components,
  MediaQueryHelper,
} from "GlobalSite";

const compName = "comp_aem_hp-avantages";
const Log = logFactory(compName);
const { log } = Log;
const {
  compTechUtilTooltipmanager: { TooltipManager },
} = components;

const { greaterThan, on } = MediaQueryHelper;
log("--->");

$(function domReady() {
  if (!isCompExists(compName, Log)) {
    return;
  }

  const $avantagesContainer = $(".hp-avantages");
  new TooltipManager({
    tooltipCtaSelector: ".tooltip-cta",
    tooltipSelector: ".tooltip",
    arrowSelector: ".arrow",
    $tooltipsContainer: $avantagesContainer,
  });

  //  Fix: AER_TCAAS1229-5814 :  change dom order on desktop so that tab order follow desktop layout
  on(greaterThan("m", { strict: true }), {
    success() {
      const $footer = $avantagesContainer.find(".hp-avantages__footer");
      const $content = $avantagesContainer.find(".hp-avantages__content");
      $footer.insertBefore($content);
    },
  });
});
